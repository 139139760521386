import { ISocialState, IState } from '@models';
import { IUserShort } from '@models/user';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getUserTypeAndId } from '@utils/users';

import {
  adapter,
  getSocialCollectionInitialState,
  NAMESPACE,
} from '../reducers/social';

export const selectSocialState = createFeatureSelector<ISocialState>(NAMESPACE);

const selectEntities = createSelector(
  selectSocialState,
  (state) => state.entities
);
const selectIsPostLoading = createSelector(
  selectSocialState,
  (state) => state.isLoading
);
const selectIsLikeLoading = createSelector(
  selectSocialState,
  (state) => state.isLikeLoading
);
const selectIsCommentLoading = createSelector(
  selectSocialState,
  (state) => state.isCommentLoading
);
const selectIsCommentsLoading = createSelector(
  selectSocialState,
  (state) => state.isCommentsLoading
);
const selectIsFollowLoading = createSelector(
  selectSocialState,
  (state) => state.isFollowLoading
);

export const getPost = (postId: number) =>
  createSelector(selectEntities, (entities) => entities[postId]);

export const isPostLoading = (postId: number) =>
  createSelector(selectIsPostLoading, (state) => state[postId] === true);

export const isLikeLoading = (postId: number) =>
  createSelector(selectIsLikeLoading, (state) => state[postId] === true);

export const isCommentLoading = (postId: number) =>
  createSelector(selectIsCommentLoading, (state) => state[postId] === true);

export const areCommentsLoading = (postId: number) =>
  createSelector(selectIsCommentsLoading, (state) => state[postId] === true);

export const isFollowLoading = (user: IUserShort) => {
  const [_userType, userId] = getUserTypeAndId(user);
  return createSelector(
    selectIsFollowLoading,
    (state) => state[userId] === true
  );
};

export function getSocialSelectors(collectionId: string) {
  const getCollectionStore = (state: IState) => {
    const socialStore = selectSocialState(state);
    return (
      socialStore.collections[collectionId] || getSocialCollectionInitialState()
    );
  };
  const { selectIds } = adapter.getSelectors(getCollectionStore);

  return {
    selectAll: createSelector(selectIds, selectEntities, (ids, entities) =>
      ids?.map((id) => entities[id])
    ),
    isLoading: createSelector(getCollectionStore, (state) => state.isLoading),
    showMore: createSelector(getCollectionStore, (state) => state.showMore),
    length: createSelector(
      getCollectionStore,
      (state) => state.ids?.length || 0
    ),
  };
}
